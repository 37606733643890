import { useMutation, useQuery, useQueryClient } from "react-query";
import { t } from "@/i18n-js/instance";
import {
  reactQueryGet,
  reactQueryPut,
} from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-shared/uikit/ToastV2";

const SPACES_INDEX_QUERY_KEY = ["internal_api", "community_bot", "spaces"];
const SETTING_SHOW_QUERY_KEY = ["internal_api", "community_bot", "setting"];

interface UseModalProps {
  onSuccess: () => void;
}

interface SpaceProp {
  id: number;
  name: string;
  posts_count: number;
  created_at: string;
  updated_at: string;
}

interface SpaceResponse {
  records: Array<SpaceProp>;
  count: number;
}

interface UpdateSpaceRequest {
  id: number;
  active: boolean;
}

interface ApiUpdateSpaceRequest {
  community_bot_space: {
    active: boolean;
  };
}

interface ApiUpdateSpaceResponse {
  id: number;
  name: string;
  posts_count: number;
  used_in_replies: number;
  active: boolean;
  created_at: string;
  updated_at: string;
}

interface SettingResponse {
  id: number;
  all_spaces_enabled: boolean;
  hide_knowledge_banner: boolean;
  hide_agents_banner: boolean;
  hide_preview_banner: boolean;
}

interface ApiUpdateSettingResponse {
  all_spaces_enabled: boolean;
  hide_knowledge_banner: boolean;
  hide_agents_banner: boolean;
  hide_preview_banner: boolean;
}

interface UpdateSettingRequest {
  all_spaces_enabled?: boolean;
  hide_knowledge_banner?: boolean;
  hide_agents_banner?: boolean;
  hide_preview_banner?: boolean;
}

interface ApiUpdateSettingRequest {
  community_bot_setting: {
    all_spaces_enabled?: boolean;
    hide_knowledge_banner?: boolean;
    hide_agents_banner?: boolean;
    hide_preview_banner?: boolean;
  };
}

export const useIndexSpaces = ({ currentPage }: { currentPage: number }) =>
  useQuery<SpaceResponse>({
    queryKey: SPACES_INDEX_QUERY_KEY,
    queryFn: () =>
      reactQueryGet(
        internalApi.communityBot.spaces.index({
          params: {
            page: currentPage,
            per_page: 10,
          },
        }),
      ),
  });

export const useUpdateSpace = ({ onSuccess }: UseModalProps) => {
  const queryClient = useQueryClient();
  const { success, error } = useToast();

  return useMutation<ApiUpdateSpaceResponse, Error, UpdateSpaceRequest>(
    (data: UpdateSpaceRequest) => {
      const { id, ...rest } = data;
      const apiData: ApiUpdateSpaceRequest = {
        community_bot_space: {
          ...rest,
        },
      };
      return reactQueryPut(
        internalApi.communityBot.spaces.update({ id }),
        apiData,
      );
    },
    {
      onSuccess: data => {
        void queryClient.invalidateQueries(SPACES_INDEX_QUERY_KEY);
        void queryClient.invalidateQueries(SETTING_SHOW_QUERY_KEY);
        void queryClient.invalidateQueries(["spaces", data.id]);
        onSuccess();
        success(t("changes_saved"));
      },
      onError: () => {
        error(t("request_failure_message"));
      },
    },
  );
};

export const useShowSetting = () =>
  useQuery<SettingResponse>({
    queryKey: SETTING_SHOW_QUERY_KEY,
    queryFn: () => reactQueryGet(internalApi.communityBot.setting.show()),
  });

export const useUpdateSetting = () => {
  const queryClient = useQueryClient();
  const { success, error } = useToast();

  return useMutation<ApiUpdateSettingResponse, Error, UpdateSettingRequest>(
    (data: UpdateSettingRequest) => {
      const apiData: ApiUpdateSettingRequest = {
        community_bot_setting: {
          ...data,
        },
      };
      return reactQueryPut(internalApi.communityBot.setting.update(), apiData);
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(SETTING_SHOW_QUERY_KEY);
        void queryClient.invalidateQueries(SPACES_INDEX_QUERY_KEY);

        success(t("changes_saved"));
      },
      onError: () => {
        error(t("request_failure_message"));
      },
    },
  );
};
