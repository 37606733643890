import { usePunditUserContext } from "@circle-react/contexts";
import { isCommunityAdmin } from "@circle-react/helpers/communityMemberHelpers";
import { useUsageContext } from "@circle-react/providers";
import type { Views } from "@circle-react-shared/MembersList/constants";
import { VIEWS } from "@circle-react-shared/MembersList/constants";
import { useMemberDirectorySettingsDefaultValues } from "./Header/Settings/useMemberDirectorySettingsDefaultValues";
import type { MemberDirectorySettings } from "./constants";

export const useMemberDirectorySettings = (): MemberDirectorySettings => {
  const { isAdminUsageContext } = useUsageContext();
  const { currentCommunitySettings, currentCommunityMember, isLoading }: any =
    usePunditUserContext();

  const isHeadlineAsCustomProfileFieldEnabled = Boolean(
    currentCommunitySettings?.headline_as_custom_profile_field_enabled,
  );

  const memberDirectorySettingsDefaultValues =
    useMemberDirectorySettingsDefaultValues(
      isHeadlineAsCustomProfileFieldEnabled,
      currentCommunitySettings,
    );

  const memberDirectorySettings =
    currentCommunitySettings?.member_directory_settings ||
    memberDirectorySettingsDefaultValues;

  const adminScope = () => {
    if (isHeadlineAsCustomProfileFieldEnabled) {
      return {
        exclude_empty_name: isAdminUsageContext ? false : true,
      };
    }
    return {
      exclude_empty_name: isAdminUsageContext ? false : true,
      exclude_empty_profiles: isAdminUsageContext
        ? false
        : memberDirectorySettings?.exclude_empty_profiles,
    };
  };

  const memberScope = {
    visible_in_member_directory: true,
    ...adminScope(),
  };

  const scope = isCommunityAdmin(currentCommunityMember)
    ? adminScope()
    : memberScope;

  const getView = () => {
    let view: Views = VIEWS.CARDS;
    if (currentCommunitySettings?.member_directory_settings?.view) {
      view = currentCommunitySettings.member_directory_settings.view;
    }
    return view;
  };

  return {
    defaultSort: memberDirectorySettings.sort,
    scope,
    view: getView(),
    isLoading,
  };
};
