import { useState } from "react";
import type { Cell } from "@tanstack/react-table";
import { isEmpty } from "lodash";
import { t } from "@/i18n-js/instance";
import { FormWrapper } from "@/react/components/SettingsApp/Layout/FormWrapper";
import { usePunditUserContext } from "@/react/contexts/punditUserContext";
import { Icon } from "@circle-react-shared/Icon";
import { DataTable } from "@circle-react-shared/uikit/DataTableV2";
import { Button } from "@circle-react-uikit/Button";
import { EmptyState } from "@circle-react-uikit/EmptyState";
import { Loader } from "@circle-react-uikit/Loader";
import { Typography } from "@circle-react-uikit/Typography";
import { useAddContentModal } from "./AddContentModal";
import { useAddSnippetModal } from "./AddSnippetModal";
import { DropdownActions } from "./DropdownActions";
import { SnippetsList } from "./SnippetsList";
import { useIndexSnippets } from "./snippetsQueries";

export interface Snippet {
  id: number;
  title: string;
  used_in_replies: number;
}

type SnippetTableCell<TValue> = Cell<Snippet, TValue>;

const columns = [
  {
    header: t("settings.community_bot.knowledge.custom.columns.title"),
    accessorKey: "title",
    cell: ({ row }: any) => (
      <Typography.LabelSm weight="semibold">
        {row.original?.title}
      </Typography.LabelSm>
    ),
    canSort: false,
    cellClassName: "w-2/3",
  },
  {
    header: t(
      "settings.community_bot.knowledge.custom.columns.used_in_replies",
    ),
    accessorKey: "used_in_replies",
    cell: ({ row }: any) => (
      <Typography.LabelSm weight="regular">
        {row.original?.used_in_replies}
      </Typography.LabelSm>
    ),
    canSort: false,
  },
  {
    accessorKey: "actions",
    header: "",
    cell: ({ row }: SnippetTableCell<unknown>) => (
      <DropdownActions snippet={row.original} />
    ),
  },
];

export const Snippets = () => {
  const [page, setPage] = useState(1);
  const snippetsQuery = useIndexSnippets({ currentPage: page });

  const addSnippetModal = useAddSnippetModal();
  const addContentModal = useAddContentModal();

  const { currentCommunitySettings } = usePunditUserContext();

  const openAddContentModal = () => {
    void addContentModal.show();
  };

  const openAddSnippetModal = () => {
    void addSnippetModal.show();
  };

  const onChangePage = (page: number) => {
    // Forcing useQuery to only cache one page
    setPage(page);
    snippetsQuery.remove();
  };

  const snippetsCount = snippetsQuery?.data?.count || 0;
  const hasNoResults = isEmpty(snippetsQuery?.data?.records || []);

  if (snippetsQuery?.isLoading) {
    return <Loader />;
  }

  if (hasNoResults) {
    return (
      <div className="mt-[20vh]">
        <EmptyState
          header={t(
            "settings.community_bot.knowledge.custom.empty_state.header",
          )}
          description={t(
            "settings.community_bot.knowledge.custom.empty_state.description",
          )}
          primaryLabel={t(
            "settings.community_bot.knowledge.custom.empty_state.primary_label",
          )}
          onPrimaryClick={openAddSnippetModal}
        />
      </div>
    );
  }

  return (
    <>
      <div className="px-8 pt-6">
        <div className="flex gap-3 rounded-xl border border-zinc-200 bg-zinc-100 p-4 pr-6 dark:border-zinc-700 dark:bg-zinc-800">
          <Icon type="20-info" size={20} />
          <Typography.BodySm>
            {t("settings.community_bot.knowledge.custom.banner")}
          </Typography.BodySm>
        </div>
      </div>
      {snippetsCount > 0 && (
        <div className="px-6 pb-1 pt-5 lg:px-9">
          <div className="flex min-h-[1.75rem] items-center justify-between gap-4">
            <Typography.LabelMd weight="semibold">
              {t("settings.community_bot.knowledge.custom.count", {
                count: snippetsCount,
              })}
            </Typography.LabelMd>
            {currentCommunitySettings?.ai_agents_v2_enabled ? (
              <Button
                type="button"
                onClick={openAddContentModal}
                variant="circle"
              >
                <Icon
                  className="mr-1"
                  type="20-plus-v3"
                  size={20}
                  useWithStrokeCurrentColor
                />
                {t(
                  "settings.community_bot.knowledge.custom.add_content_modal.title",
                )}
              </Button>
            ) : (
              <Button
                type="button"
                onClick={openAddSnippetModal}
                variant="circle"
              >
                {t("settings.community_bot.knowledge.custom.add_snippet")}
              </Button>
            )}
          </div>
        </div>
      )}
      <DataTable.TableWrapper>
        <FormWrapper>
          <SnippetsList
            columns={columns}
            snippetsQuery={snippetsQuery}
            onChangePage={onChangePage}
          />
        </FormWrapper>
      </DataTable.TableWrapper>
    </>
  );
};
