import { AddressElement } from "@stripe/react-stripe-js";
import { isEmpty } from "lodash";
import { isBuyNowPayLater } from "@/react/helpers/paywallCheckoutHelpers";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { stripeAddressElementOptions } from "../../StripeElements/stripeElementsOptions";
import { CheckoutFormField } from "./CheckoutFormField";
import { CheckoutFormFieldWrapper } from "./CheckoutFormFieldWrapper";

export const CheckoutFormBillingInfoFields = () => {
  const {
    currentUser,
    isMemberAddressRequired: isMemberAddressRequired,
    setMemberAddressAttributes,
    stripePaymentMethodType,
    memberBillingInfo,
  } = usePaywallCheckoutContext();

  const shouldRenderAddressElement =
    isMemberAddressRequired ||
    (!currentUser && isBuyNowPayLater(stripePaymentMethodType)) ||
    (currentUser &&
      isBuyNowPayLater(stripePaymentMethodType) &&
      isEmpty(memberBillingInfo));

  if (!shouldRenderAddressElement && currentUser) return null;

  if (shouldRenderAddressElement) {
    return (
      <AddressElement
        options={stripeAddressElementOptions}
        onChange={setMemberAddressAttributes}
      />
    );
  }
  return (
    <CheckoutFormFieldWrapper name="name">
      <CheckoutFormField type="text" name="name" options={{ required: true }} />
    </CheckoutFormFieldWrapper>
  );
};
