import type { ReactNode } from "react";
import classNames from "classnames";

export interface ContentProps {
  className?: string;
  hasPointer?: boolean;
  children: ReactNode;
}

export const Content = ({
  className,
  hasPointer = false,
  children,
}: ContentProps) => (
  <div
    className={classNames("flex items-center justify-center", className, {
      "cursor-pointer": hasPointer,
    })}
  >
    {children}
  </div>
);
