import { t } from "@/i18n-js/instance";
import {
  isCommunityAdmin,
  isCommunityModerator,
} from "@/react/helpers/communityMemberHelpers";
import { settingsPath } from "@/react/helpers/urlHelpers";
import { useAdminIcons } from "@circle-react/components/Spaces/SidebarV2/LogoTopSection/useAdminIcons";
import { usePunditUserContext } from "@circle-react/contexts";
import { useSmOrMdScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";

export const usePostPageLink = () => {
  const { currentCommunityMember } = usePunditUserContext();
  const isMobile = useSmOrMdScreenMediaQuery();
  const { postsIcon: icon } = useAdminIcons();

  const isVisible = Boolean(
    !isMobile &&
      (isCommunityAdmin(currentCommunityMember) ||
        isCommunityModerator(currentCommunityMember)),
  );

  const title = t("settings.posts.title");
  const link = settingsPath.postsDashboard.index();

  return {
    isVisible,
    link,
    icon,
    iconSize: 20,
    title,
  };
};
