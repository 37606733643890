import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers/settingsNavPathV3";

export interface StandardLayoutV2WrapperProps
  extends ComponentPropsWithoutRef<"div"> {
  disableSidebar?: boolean;
  disableSidebarNestedBarLinks?: boolean;
  disableLayoutRightSidebar?: boolean;
  hasSettingsSidebar?: boolean;
  children: ReactNode;
}

export const StandardLayoutV2Wrapper = ({
  disableSidebar = false,
  disableSidebarNestedBarLinks = false,
  disableLayoutRightSidebar = false,
  hasSettingsSidebar = false,
  children,
  ...rest
}: StandardLayoutV2WrapperProps) => {
  const { pathname } = useLocation();

  const isCommunityInboxPage = pathname.includes(
    settingsNavPathV3.community_inbox.index,
  );

  return (
    <div
      className={classNames("standard-layout-v2 bg-secondary", {
        "standard-layout-v2--has-sidebar": !disableSidebar,
        "standard-layout-v2--has-sidebar-v3": !disableSidebar,
        "standard-layout-v2--has-settings-sidebar":
          hasSettingsSidebar && !isCommunityInboxPage,
        "standard-layout-v2--has-community-inbox-sidebar":
          isCommunityInboxPage && hasSettingsSidebar,
        "standard-layout-v2--no-right-sidebar": disableLayoutRightSidebar,
        "standard-layout-v2--hidden-nested-links": disableSidebarNestedBarLinks,
      })}
      data-testid="standard-layout-v2-wrapper"
      {...rest}
    >
      {children}
    </div>
  );
};
