import { useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { Icon } from "@/react/components/shared/Icon";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

const MAX_TAGS = 10;

export const TagsInput = () => {
  const [inputValue, setInputValue] = useState("");
  const { setValue } = useFormContext();
  const tags = useWatch({ name: "pause_ai_keywords" }) || [];
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      event.preventDefault();
      if (!tags.includes(inputValue.trim())) {
        setValue("pause_ai_keywords", [...tags, inputValue.trim()]);
        setInputValue("");
      }
    }
  };

  const removeTag = (tagToRemove: string) => {
    setValue(
      "pause_ai_keywords",
      tags.filter((tag: string) => tag !== tagToRemove),
    );
  };

  useEffect(() => {
    const checkFocus = () => {
      setIsFocused(document.activeElement === inputRef.current);
    };

    document.addEventListener("focusin", checkFocus);
    document.addEventListener("focusout", checkFocus);

    return () => {
      document.removeEventListener("focusin", checkFocus);
      document.removeEventListener("focusout", checkFocus);
    };
  }, []);

  return (
    <div
      className={classNames("form-control h-fit-content min-h-[6.625rem]", {
        "!border-light": isFocused,
      })}
      onClick={() => inputRef.current?.focus()}
      role="tab"
      tabIndex={0}
      onKeyDown={e => {
        if (e.key === "Enter" || e.key === " ") {
          inputRef.current?.focus();
        }
      }}
    >
      <span className="mb-2 flex flex-wrap gap-2">
        {tags.map((tag: string) => (
          <div
            key={tag}
            className="bg-secondary-dark text-dark flex items-center gap-1 rounded-md py-1 pl-3 pr-2 text-sm font-medium"
          >
            {tag}
            <Icon
              type="16-close"
              size={16}
              onClick={() => removeTag(tag)}
              className="text-default cursor-pointer"
            />
          </div>
        ))}
        {tags.length <= MAX_TAGS && (
          <input
            ref={inputRef}
            type="text"
            className="grow border-none p-0.5 placeholder:text-sm placeholder:font-medium focus:shadow-none focus:outline-none focus:ring-0"
            placeholder={t(
              "community_bot.bot_builder.behavior_page.pause_ai_setting.pause_ai_keywords_placeholder",
            )}
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        )}
      </span>
    </div>
  );
};
