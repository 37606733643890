import {
  CHANNEL_NAME,
  isAccessGroupManageMembersTask,
  isAsyncTaskChannelFinished,
  isAsyncTaskChannelUpdated,
  isAsyncTaskCompleted,
} from "@/react/helpers/asyncTaskHelpers";
import { useWebSocket } from "@/react/hooks/useWebSocket";
import { usePunditUserContext } from "@circle-react/contexts";
import { accessGroupPaths } from "@circle-react/helpers/urlHelpers/accessGroupPaths";
import { queryClient } from "@circle-react/providers";
import { CustomProgressToast } from "@circle-react-shared/uikit/ToastV2";
import type { CustomProgressToastProps } from "@circle-react-shared/uikit/ToastV2/CustomProgressToast";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useAccessGroupData } from "../AccessGroupMembers/DataStoreContext/AccessGroupDataContext";
import { useCustomProgressToast } from "./useCustomProgressToast";

export interface Message {
  id: number;
  type: string;
  params: {
    process_type: string;
    access_group_id: number;
  };
  progress: string;
  status: string;
  message: string;
}

export const useAccessGroupWebSocket = () => {
  const { currentCommunityMember } = usePunditUserContext();

  const { toastsIdsMap, setToastsIdsMap, updateToastProgress } =
    useCustomProgressToast();

  const { setShouldBlockActions, shouldBlockActions } = useAccessGroupData();

  const { error: errorToast, show } = useToast();

  const isCurrentPathAccessGroup = (accessGroupId: number) => {
    const accessGroupEditPath = accessGroupPaths.edit({
      accessGroupId: accessGroupId,
    });

    return accessGroupEditPath === window.location.pathname;
  };

  const onMessageReceive = async (eventData: any) => {
    const { event, record } = eventData;
    const parsedRecord: Message = JSON.parse(record);
    const { id: toastIdNumber, params } = parsedRecord;
    const toastId = String(toastIdNumber);
    const shouldHideToast = !isCurrentPathAccessGroup(params?.access_group_id);

    if (!isAccessGroupManageMembersTask(parsedRecord?.type)) {
      return;
    }

    if (!toastsIdsMap[toastId]) {
      setToastsIdsMap(prevState => {
        show("", {
          id: String(toastId),
          shouldAutohide: false,
          render: (args: CustomProgressToastProps) => (
            <CustomProgressToast {...args} />
          ),
        });
        return { ...prevState, [toastId]: true };
      });
    }

    if (isAsyncTaskChannelUpdated(event)) {
      if (!shouldBlockActions) {
        setShouldBlockActions(true);
      }

      updateToastProgress({
        toastId,
        // TODO: If the backend sends a progress field, we can use it in the ProgressIcon progress prop
        customProgress: 0,
        message: parsedRecord.progress,
        shouldHideToast,
      });
    } else if (isAsyncTaskChannelFinished(event)) {
      if (isAsyncTaskCompleted(parsedRecord.status)) {
        updateToastProgress({
          toastId,
          customProgress: 100,
          message: parsedRecord.message,
          shouldHideToast,
        });
      } else {
        errorToast(parsedRecord.message);
      }
      setShouldBlockActions(false);
    }
    await queryClient.invalidateQueries("community-members");
    await queryClient.invalidateQueries(["access_groups", "show"]);
  };

  useWebSocket({
    channel: CHANNEL_NAME,
    onMessageReceive: onMessageReceive,
    canCreateConnection: !!currentCommunityMember?.id,
    community_member_id: currentCommunityMember?.id,
  });
};
