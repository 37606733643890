import type { CommunitySettings } from "@circle-react/types/CommunitySettings";
import { SORT_OPTIONS } from "@circle-react-shared/MembersList/constants";

export const useMemberDirectorySettingsDefaultValues = (
  isHeadlineAsCustomProfileFieldEnabled: boolean,
  currentCommunitySettings: CommunitySettings,
) => {
  if (isHeadlineAsCustomProfileFieldEnabled) {
    return {
      sort: SORT_OPTIONS[0],
    };
  }

  return currentCommunitySettings?.member_directory_settings;
};
