import type { ReactNode } from "react";
import { useRouteMatch } from "react-router-dom";
import { BrowserNotificationPrompt } from "@/react/components/Layout/BrowserNotificationPrompt";
import { MobileBottomToolbar } from "@/react/components/Layout/MobileBottomToolbar";
import { Body } from "@/react/components/Layout/StandardLayoutV2/Body";
import { Breadcrumb } from "@/react/components/Layout/StandardLayoutV2/Breadcrumb";
import { Content } from "@/react/components/Layout/StandardLayoutV2/Content";
import { CoverImage } from "@/react/components/Layout/StandardLayoutV2/CoverImage";
import { Header } from "@/react/components/Layout/StandardLayoutV2/Header";
import { Right } from "@/react/components/Layout/StandardLayoutV2/Right";
import { SideBar } from "@/react/components/Spaces/Sidebar";
import {
  isChatSpace,
  isGridOrMasonryViewImageSpace,
} from "@/react/helpers/spaceHelpers";
import { usePostListPinnedOnSidebar } from "@/react/hooks/posts/usePostList";
import { useAppendBodyClasses } from "@/react/hooks/useAppendBodyClasses";
import { useRouterQueryParams } from "@/react/hooks/useRouterQueryParams";
import { AppBanner } from "@circle-react/components/AppBanner";
import { useSettingsHeader } from "@circle-react/components/shared/uikit/HeaderV3/useSettingsHeader";
import {
  useCurrentSpaceContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { useLayoutPartialsContext } from "@circle-react/contexts/layoutPartialsContext";
import {
  isPromoCodeEnabled,
  isSubscriptionExpired,
} from "@circle-react/helpers/communityHelpers";
import { shouldRenderBillingAfterCheckout } from "@circle-react/helpers/plansHelpers";
import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { useMasqueradeEnabledAndActive } from "@circle-react/hooks/useMasqueradeEnabledAndActive";
import { AppBar } from "../AppBar";
import { MobileDrawer } from "../MobileDrawer";
import { CommunityContent } from "./CommunityContent";
import { CommunityWrapper } from "./CommunityWrapper";
import { ContentWrapper } from "./ContentWrapper";
import { RailbarContainer } from "./RailbarContainer";
import { ShowOnSmOrMdScreen } from "./ShowOnSmOrMdScreen";
import { SidebarWrapper } from "./SidebarWrapper";
import { StandardLayoutV2Wrapper } from "./StandardLayoutV2Wrapper";
import { TrialBannerV2 } from "./TrialBannerV2";
import { shouldDisableRightSidebar } from "./shouldDisableRightSidebar";
import { useShouldShowMobileBottomBar } from "./useMobileBottomBar";
import { useTopBarClassNames } from "./useTopBarClassNames";
import "./overrides.scss";
import "./styles.scss";

interface RouteParams {
  postSlug?: string;
}

export interface StandardLayoutV2Props {
  bodyClassNames?: string;
  children: ReactNode;
  disableRightSidebar?: boolean;
  disableScroll?: boolean;
  disableSidebar?: boolean;
  hideAppBar?: boolean;
  shouldHideSidebarNestedNavBar?: boolean;
  id?: string;
  renderLeftSidebar?: () => ReactNode;
}

const StandardLayoutV2Root = ({
  bodyClassNames = "",
  children,
  disableRightSidebar = false,
  disableScroll = false,
  disableSidebar = false,
  hideAppBar = false,
  shouldHideSidebarNestedNavBar = false,
  id,
  renderLeftSidebar,
}: StandardLayoutV2Props) => {
  const { currentCommunityMember, currentCommunity } = usePunditUserContext();
  const { posts: pinnedPosts } = usePostListPinnedOnSidebar();
  const containerProps: any = { id };
  const partialsContext: any = useLayoutPartialsContext();
  const mobile_drawer_html = partialsContext?.data?.mobile_drawer_html;
  const isMasqueradingEnabledAndActive = useMasqueradeEnabledAndActive();

  const { isSettingsRoute, isReactFlowWorkflowsRoute } = useSettingsHeader();
  const shouldShowMobileBottomToolbar = useShouldShowMobileBottomBar();

  if (!disableSidebar && mobile_drawer_html) {
    containerProps["data-controller"] = "mobile-sidebar";
  }

  useAppendBodyClasses(
    classNames(
      "is-standard-layout-v2 react-standard-layout bg-secondary is-standard-layout-v2--v3-enabled",
      bodyClassNames,
      {
        "is-standard-layout-v2--v3-enabled--hidden-nested-links":
          shouldHideSidebarNestedNavBar,
      },
    ),
  );
  const queryParams = useRouterQueryParams();

  const match = useRouteMatch<RouteParams>("/c/:spaceSlug/:postSlug");
  const postSlug = match?.params?.postSlug;

  const isHomePage = useRouteMatch("/home")?.isExact;
  const isFeedPage = useRouteMatch("/feed")?.isExact;
  const { data } = useCurrentSpaceContext();
  const hasSubscriptionExpired =
    isSubscriptionExpired(currentCommunity) &&
    !shouldRenderBillingAfterCheckout(queryParams);
  const isPromoCodeEnabledForCommunity = isPromoCodeEnabled(currentCommunity);
  const space = data || {};

  // TODO: refactor so that we rely on child props to set this value.
  const disableLayoutRightSidebar = shouldDisableRightSidebar({
    disableRightSidebar,
    postSlug,
    space,
    pinnedPosts,
    isHomePage: isHomePage || isFeedPage,
    currentCommunityMember,
  });

  const isBgPrimarySpace =
    isChatSpace(space) ||
    isGridOrMasonryViewImageSpace(space) ||
    isPromoCodeEnabledForCommunity;

  const isPromoCodeEnabledAndHasSubscriptionExpired =
    isPromoCodeEnabledForCommunity && hasSubscriptionExpired;

  const topBarClassNames = useTopBarClassNames(
    isPromoCodeEnabledAndHasSubscriptionExpired,
    isMasqueradingEnabledAndActive,
  );

  return (
    <CommunityWrapper {...containerProps}>
      <div data-testid="mobile-drawer-wrapper">
        {!disableSidebar && <MobileDrawer />}
      </div>
      <div
        className={classNames(
          "bg-secondary flex w-full flex-col",
          topBarClassNames,
          {
            "h-screen": !disableScroll,
            "h-full": disableScroll,
          },
        )}
        data-testid="standard-layout-v2"
      >
        <TrialBannerV2 />
        <AppBanner />
        <CommunityContent disableSidebar={disableSidebar}>
          {!hideAppBar && <AppBar />}
          <StandardLayoutV2Wrapper
            disableSidebar={disableSidebar}
            disableLayoutRightSidebar={disableLayoutRightSidebar}
            hasSettingsSidebar={
              !isReactFlowWorkflowsRoute &&
              isSettingsRoute &&
              !hasSubscriptionExpired &&
              !isPromoCodeEnabledForCommunity
            }
            disableSidebarNestedBarLinks={shouldHideSidebarNestedNavBar}
          >
            {!disableSidebar && !isReactFlowWorkflowsRoute && (
              <SidebarWrapper>
                {renderLeftSidebar ? renderLeftSidebar() : <SideBar />}
              </SidebarWrapper>
            )}
            <ContentWrapper
              isBgSecondary={!isBgPrimarySpace}
              isBgPrimary={isBgPrimarySpace}
            >
              {children}
            </ContentWrapper>
            <div className="standard-layout-v2__railbar">
              <RailbarContainer />
            </div>
            <BrowserNotificationPrompt />
            <div id="mobile-dropdown-container" />
            {shouldShowMobileBottomToolbar && (
              <ShowOnSmOrMdScreen>
                <MobileBottomToolbar />
              </ShowOnSmOrMdScreen>
            )}
          </StandardLayoutV2Wrapper>
        </CommunityContent>
      </div>
    </CommunityWrapper>
  );
};

export const StandardLayoutV2 = Object.assign(StandardLayoutV2Root, {
  Header,
  Breadcrumb,
  Content,
  Body,
  Right,
  CoverImage,
});
