import { notifyBugsnag } from "@/react/helpers/bugsnagHelpers";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { isCard } from "@circle-react/helpers/paywallCheckoutHelpers";
import {
  GENERAL_ERROR,
  PIX_MODAL_CLOSED,
  buildErrorMessage,
  getReferral,
} from "../helpers";
import { useBuyNowPayLater } from "./paymentMethods/useBuyNowPayLater";
import { useCard } from "./paymentMethods/useCard";
import { usePix } from "./paymentMethods/usePix";

const manageError = (error: any, handleError: any, options: any) => {
  if (error?.cause?.error_details === PIX_MODAL_CLOSED.error_details) return;

  notifyBugsnag(error);
  console.error("Error: unexpected error occurred: ", error);

  handleError(
    buildErrorMessage(
      {
        message: error.message,
        error_details: error?.cause?.error_details || GENERAL_ERROR,
      },
      options,
    ),
  );
};

export const useOnSubmit = () => {
  const {
    handleError,
    setIsProcessingPayment,
    loginUrl,
    resetPasswordUrl,
    stripePaymentMethodType,
  } = usePaywallCheckoutContext();
  const cardHooks = useCard();
  const pixHooks = usePix();
  const buyNowPayLaterHooks = useBuyNowPayLater();

  const paymentMethodHookStrategies: any = {
    pix: pixHooks,
    card: cardHooks,
    klarna: buyNowPayLaterHooks,
    afterpay_clearpay: buyNowPayLaterHooks,
    affirm: buyNowPayLaterHooks,
    google_pay: cardHooks,
    apple_pay: cardHooks,
  };

  const onSubmit = async (formData: any) => {
    if (getReferral()) {
      formData.referral_metadata = getReferral();
    }

    const validPaymentMethodType = isCard(formData.payment_method_type)
      ? stripePaymentMethodType
      : formData.payment_method_type;

    if (!paymentMethodHookStrategies[validPaymentMethodType]) {
      throw new Error(`Invalid payment method type ${validPaymentMethodType}`);
    }

    const { onSubmitMutation } =
      paymentMethodHookStrategies[validPaymentMethodType];

    try {
      setIsProcessingPayment(true);
      await onSubmitMutation.mutateAsync(formData);
    } catch (error: any) {
      manageError(error, handleError, { loginUrl, resetPasswordUrl });
    } finally {
      setIsProcessingPayment(false);
    }
  };

  return {
    onSubmit,
  };
};
