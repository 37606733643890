import type { ReactNode } from "react";
import { Tippy } from "@circle-react/components/shared/Tippy";
import { Content } from "./Content";

export interface ActionProps {
  tooltipContent?: string;
  className?: string;
  children: ReactNode;
}

export const Action = ({
  tooltipContent,
  className,
  children,
}: ActionProps) => {
  if (tooltipContent) {
    return (
      <Tippy placement="top" content={tooltipContent}>
        <Content className={className} hasPointer>
          {children}
        </Content>
      </Tippy>
    );
  }

  return <Content className={className}>{children}</Content>;
};
