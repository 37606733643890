import { t } from "@/i18n-js/instance";
import type { DropdownLink } from "./types";
import { useAdminIcons } from "./useAdminIcons";

export const useGetModeration = (moderationCount?: number): DropdownLink => {
  const { moderationIcon } = useAdminIcons();

  const moderation: DropdownLink = {
    value: t("sidebar_moderation"),
    label: t("sidebar_moderation"),
    pathTo: "/settings/moderation",
    icon: moderationIcon,
    iconSize: 20,
  };

  if (moderationCount) {
    moderation.badge = String(moderationCount);
  }

  return moderation;
};
