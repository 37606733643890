import { t } from "@/i18n-js/instance";
import { FormItem } from "@/react/components/SettingsApp/General/FormItem";
import type { AgentProp } from "@circle-react/components/SettingsApp/CommunityBot/agentsQueries";
import { Form } from "@circle-react-shared/uikit/Form";
import { FormTipTapEditor } from "@circle-react-shared/uikit/Form/FormTipTapEditor";
import { Typography } from "@circle-react-shared/uikit/Typography";
import { TagsInput } from "./TagsInput";

interface FormDataProps
  extends Pick<AgentProp, "pause_ai_keywords" | "pause_ai_handoff_message"> {}

export const PauseAISetting = ({
  formDefaultValues,
}: {
  formDefaultValues: FormDataProps;
}) => (
  <div className="border-primary border-t px-6 py-5">
    <Typography.LabelMd weight="semibold">
      {t("community_bot.bot_builder.behavior_page.pause_ai_setting.title")}
    </Typography.LabelMd>
    <div className="mt-4">
      <Typography.LabelSm color="text-light">
        {t(
          "community_bot.bot_builder.behavior_page.pause_ai_setting.description",
        )}
      </Typography.LabelSm>
    </div>
    <FormItem
      name="pause_ai_keywords"
      translationRoot="community_bot.bot_builder.behavior_page.pause_ai_setting"
      hidePlaceholder
      hideDescription
    >
      <TagsInput />
    </FormItem>
    <Form.Label
      fieldId="pause_ai_handoff_message"
      label={t(
        "community_bot.bot_builder.behavior_page.pause_ai_setting.handoff_message",
      )}
      hideBorder
      labelClassName="cursor-text"
    />
    <FormTipTapEditor
      name="pause_ai_handoff_message"
      value={formDefaultValues.pause_ai_handoff_message}
      showMenuBar={false}
      submitOnEnter={false}
      className="!border-primary !bg-primary focus-within:!border-light !mb-0 !px-4 !text-base"
      editorClassName="min-h-[5rem]"
      type="workflow"
    />
  </div>
);
