import { useMemo } from "react";
import { t } from "@/i18n-js/instance";
import type { WorkflowType } from "@circle-react/components/SettingsApp/Workflows/constants";
import { WORKFLOW_TYPE } from "@circle-react/components/SettingsApp/Workflows/constants";
import type { Audience } from "@circle-react/components/SettingsApp/Workflows/generated/WorkflowsApi.types";
import type { IconType } from "@circle-react-shared/Icon";
import type { TriggerTypes } from "@circle-react-shared/WorkflowsV2/TriggerNode/constants";
import { TRIGGER_TYPE_TO_ICON } from "@circle-react-shared/WorkflowsV2/TriggerNode/constants";

const i18nRoot = "settings.workflows.form";

export interface UseEntryRuleProps {
  workflowType?: WorkflowType;
  trigger?: {
    trigger_type: TriggerTypes;
  };
  audience?: Audience;
}

export interface UseEntryRuleResult {
  iconType: IconType;
  title: string;
  description: string;
}

export const useEntryRule = ({
  workflowType,
  trigger,
  audience,
}: UseEntryRuleProps): UseEntryRuleResult | null => {
  const isDynamic = workflowType === WORKFLOW_TYPE.DYNAMIC;
  const isBulkAction = workflowType === WORKFLOW_TYPE.BULK_ACTION;

  const audienceTypeParam = useMemo(() => {
    const filters = audience?.rules?.rules ?? [];
    return filters.find(filterParam => filterParam.key === "role");
  }, [audience]);

  if (
    (isDynamic && !trigger?.trigger_type) ||
    (isBulkAction && !audienceTypeParam)
  ) {
    return null;
  }

  const triggerType: TriggerTypes = trigger?.trigger_type || "default";

  const dynamicEntryRule: UseEntryRuleResult = {
    iconType: TRIGGER_TYPE_TO_ICON[triggerType],
    title: t([i18nRoot, "events", triggerType, "title"]),
    description: t([i18nRoot, "events", triggerType, "description"]),
  };

  if (isDynamic) {
    return {
      ...dynamicEntryRule,
      title: `${t([i18nRoot, "event"])}: ${dynamicEntryRule.title}`,
    };
  }

  const allFilters = audience?.rules?.rules || [];
  const filtersBasedLabel = allFilters
    .map(filterParam =>
      t(["settings.workflows.view", "audience_filters", filterParam.key]),
    )
    .join(", ");

  const bulkActionEntryRule: UseEntryRuleResult = {
    iconType: "16-workflows-v2-add-audience",
    title: filtersBasedLabel,
    description: t([i18nRoot, "audience_types", "default"]),
  };

  if (isBulkAction) {
    return {
      ...bulkActionEntryRule,
      title: `${t([i18nRoot, "audience_filters"])}: ${
        bulkActionEntryRule.title
      }`,
    };
  }

  return null;
};
