import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIsPaywallPaymentSettingsAvailable } from "@circle-react/components/Paywalls/Admin/PaymentSettings/hooks/useIsPaywallPaymentSettingsAvailable";
import { useIsPaywallTaxAvailable } from "@circle-react/components/Paywalls/Admin/TaxSettings/hooks/useIsPaywallTaxAvailable";
import { useAccessGroupWebSocket } from "@circle-react/components/SettingsApp/AccessGroups/EditAccessGroupModal/hooks/useAccessGroupWebSocket";
import { useDisableCommunityBotLeftSidebar } from "@circle-react/components/SettingsApp/CommunityBot/hooks/useDisableCommunityBotLeftSidebar";
import { useDisableCommunityInboxLeftSidebar } from "@circle-react/components/SettingsApp/CommunityInbox/hooks/useDisableCommunityInboxLeftSidebar";
import { Layout } from "@circle-react/components/SettingsApp/Layout";
import { usePunditUserContext } from "@circle-react/contexts";
import { isPromoCodeEnabled } from "@circle-react/helpers/communityHelpers";
import {
  USAGE_SECTIONS,
  UsageContextProvider,
} from "@circle-react/providers/UsageContext";
import { useRouteHelpers } from "@circle-react/routes/Main/useRouteHelpers";
import { SettingsRoutes } from "@circle-react/routes/Settings";
import { useIsWorkflowsEnabled } from "@circle-react-shared/Workflows";
import { Loader } from "@circle-react-uikit/Loader";

export const SettingsApp = () => {
  const { isLoading, currentCommunity, currentCommunitySettings } =
    usePunditUserContext();

  const isPromoCodeEnabledForCommunity = isPromoCodeEnabled(currentCommunity);
  const history = useHistory();
  const isWorkflowsEnabled = useIsWorkflowsEnabled();
  const { isSettingsPage } = useRouteHelpers();

  const isPaywallTaxAvailable =
    useIsPaywallTaxAvailable() || !currentCommunity?.payment_processor;

  const isPaywallPaymentSettingsAvailable =
    useIsPaywallPaymentSettingsAvailable();

  const shouldDisableCommunityInboxLeftSidebar =
    useDisableCommunityInboxLeftSidebar();

  const shouldDisableCommunityBotLeftSidebar =
    useDisableCommunityBotLeftSidebar();

  useEffect(() => {
    const unListen = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => unListen();
  });

  useAccessGroupWebSocket();

  if (isLoading) {
    return <Loader />;
  }

  if (!isSettingsPage) {
    return null;
  }

  const isAffiliatesEnabled =
    !!currentCommunity?.affiliates_feature_flag_enabled;

  return (
    <UsageContextProvider section={USAGE_SECTIONS.ADMIN}>
      <Layout
        shouldHideMainHeader={false}
        hideAppBar={isPromoCodeEnabledForCommunity}
        shouldHideSidebarNestedNavBar={
          shouldDisableCommunityInboxLeftSidebar ||
          shouldDisableCommunityBotLeftSidebar
        }
      >
        <SettingsRoutes
          isPaywallTaxAvailable={isPaywallTaxAvailable}
          isAffiliatesEnabled={isAffiliatesEnabled}
          isWorkflowsEnabled={isWorkflowsEnabled}
          isPaywallPaymentSettingsAvailable={isPaywallPaymentSettingsAvailable}
          isCommunityBotEnabled={Boolean(
            currentCommunitySettings?.community_bot_enabled,
          )}
        />
      </Layout>
    </UsageContextProvider>
  );
};
