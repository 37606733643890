import {
  PaymentMethodType,
  isAffirm,
  isAffirmSupported,
  isAfterpayClearpay,
  isAfterpayClearpaySupported,
  isKlarna,
  isKlarnaSupported,
} from "@circle-react/helpers/paywallCheckoutHelpers";

interface UseStripeElementOptions {
  filteredPaymentMethodsSupported: string[];
  isPaywallDigitalWalletEnabled: boolean;
  isBuyNowPayLaterEnabled: boolean; // This is a feature flag. TODO: mickeytgl: Remove this flag when this is stable
  isBuyNowPayLaterAllowed: boolean; // State that can be dynamically turned on/off, i.e. when a coupon is applied and the amount is zero
  paywall: any;
  price: any;
  amountFromPreview?: number;
}

const MODE = {
  Payment: "payment",
  Subscription: "subscription",
};
const SETUP_FUTURE_USAGE = {
  OffSession: "off_session",
};

const getPaymentMethodTypesForPrice = (
  doesSupportBuyNowPayLater: boolean,
  paymentMethodTypes: string[],
  currency: string,
  amount: number,
) =>
  doesSupportBuyNowPayLater
    ? paymentMethodTypes.filter(method => {
        if (isKlarna(method) && !isKlarnaSupported(currency, amount))
          return false;
        if (
          isAfterpayClearpay(method) &&
          !isAfterpayClearpaySupported(currency, amount)
        )
          return false;
        return !(isAffirm(method) && !isAffirmSupported(currency, amount));
      })
    : [PaymentMethodType.CARD, PaymentMethodType.LINK];

export const useStripeElementOptions = ({
  filteredPaymentMethodsSupported,
  isPaywallDigitalWalletEnabled,
  paywall,
  price,
  isBuyNowPayLaterEnabled,
  isBuyNowPayLaterAllowed,
  amountFromPreview,
}: UseStripeElementOptions) => {
  if (!isPaywallDigitalWalletEnabled) {
    return {};
  }

  const paymentMethodTypes = [
    PaymentMethodType.CARD,
    PaymentMethodType.LINK,
    PaymentMethodType.KLARNA,
    PaymentMethodType.AFTERPAY_CLEARPAY,
    PaymentMethodType.AFFIRM,
  ];

  const doesSupportBuyNowPayLater =
    price.price_type === "onetime" &&
    isBuyNowPayLaterEnabled &&
    isBuyNowPayLaterAllowed;

  const setupFutureUsage = doesSupportBuyNowPayLater
    ? null
    : SETUP_FUTURE_USAGE.OffSession;
  const amount = amountFromPreview ?? price.amount;
  const mode =
    doesSupportBuyNowPayLater && amount > 0 ? MODE.Payment : MODE.Subscription;
  const currency = paywall.currency_code.toUpperCase();

  const paymentMethodTypesForPrice = getPaymentMethodTypesForPrice(
    doesSupportBuyNowPayLater,
    paymentMethodTypes,
    currency,
    amount,
  );
  const supportedPaymentMethods = paymentMethodTypesForPrice.filter(method =>
    filteredPaymentMethodsSupported?.includes(method),
  );
  return {
    payment_method_types: supportedPaymentMethods,
    mode,
    setup_future_usage: setupFutureUsage,
    currency: paywall.currency_code,
    amount,
  };
};
