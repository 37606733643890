import { useFormContext } from "react-hook-form";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@/react/contexts/punditUserContext";
import { Button } from "@circle-react-uikit/Button";

const isTipTapBlockEditorEmpty = (body: any) =>
  typeof body === "undefined" ||
  typeof body.body === "undefined" ||
  (body?.body?.content?.length === 1 &&
    body.body.content[0].type === "paragraph" &&
    !body.body.content[0].content);

export const Footer = ({
  label,
  onBack,
}: {
  label: string;
  onBack?: () => void;
}) => {
  const {
    watch,
    formState: { isSubmitting },
  } = useFormContext();
  const { currentCommunitySettings } = usePunditUserContext();
  const { title, body } = watch();
  const isSubmitDisabled =
    !title || isTipTapBlockEditorEmpty(body) || isSubmitting;

  return (
    <footer
      className="border-primary flex items-center justify-end border-t px-5 py-4"
      data-testid="quick-post-footer"
    >
      <div className="flex items-center space-x-2">
        {currentCommunitySettings?.ai_agents_v2_enabled && onBack && (
          <Button type="button" variant="secondary" onClick={onBack}>
            {t(
              "settings.community_bot.knowledge.custom.add_file_modal.footer.back",
            )}
          </Button>
        )}
        <Button disabled={isSubmitDisabled} type="submit" variant="circle">
          {label}
        </Button>
      </div>
    </footer>
  );
};
