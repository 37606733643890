import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { usePunditUserContext } from "@circle-react/contexts";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

export interface StandardLayoutHeaderProps
  extends ComponentPropsWithoutRef<"div"> {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  filters?: ReactNode;
  headline?: ReactNode;
  hasBorder?: boolean;
}

export const StandardLayoutHeader = ({
  leftContent,
  rightContent,
  filters,
  headline,
  hasBorder = true,
  className,
  ...rest
}: StandardLayoutHeaderProps) => {
  const hasFilters = Boolean(filters);
  const hasHeadline = Boolean(headline);
  const { isViewOnlyMasquerading } = usePunditUserContext();

  return (
    <div
      {...rest}
      className={classNames(
        "bg-primary w-full lg:sticky lg:top-0 lg:z-10",
        className,
      )}
    >
      <div
        className={classNames("h-18 flex items-center gap-4 px-6 lg:px-9", {
          "border-primary border-b": hasBorder,
          "!cursor-not-allowed": isViewOnlyMasquerading,
        })}
        data-testid="space-header"
      >
        <div
          className={classNames("flex-1 truncate", {
            "!pointer-events-none": isViewOnlyMasquerading,
          })}
          data-testid="space-settings"
        >
          {leftContent}
        </div>
        <div
          className={classNames("relative flex", {
            "!pointer-events-none": isViewOnlyMasquerading,
          })}
        >
          {rightContent}
        </div>
      </div>
      {hasFilters && filters}
      {hasHeadline && headline}
    </div>
  );
};
