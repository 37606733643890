import { t } from "@/i18n-js/instance";
import { nextPlanWithFeatureApi } from "@/react/api/nextPlanWithFeatureApi";
import { usePunditUserContext } from "@circle-react/contexts";
import { useDisableModal } from "@circle-react/pages/BotBuilder/DisableModal";
import { useEnableModal } from "@circle-react/pages/BotBuilder/EnableModal";
import { useUpgradePlanModal } from "@circle-react-shared/UpgradePlan";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";
import { ToggleWithLoader } from "@circle-react-shared/uikit/ToggleWithLoader";

export const AgentEnableSwitch = ({ row }: { row: any }) => {
  const enableModal = useEnableModal(row.original);
  const disableModal = useDisableModal(row.original);
  const { currentCommunity } = usePunditUserContext();
  const upgradePlanModal = useUpgradePlanModal();

  const handleUpgradePlan = async () => {
    const nextPlanData = await nextPlanWithFeatureApi.getNextPlanWithFeature(
      (currentCommunity?.current_plan?.id ?? "").toString(),
      { plan_feature_flag: "ai_agents" },
    );

    const planTier = nextPlanData?.plan_tier || "enterprise";

    await upgradePlanModal.show({
      planTier,
      source: UPGRADE_FLOW_SOURCES.AI_AGENTS_ENABLE_MODAL,
      subheading: t("pricing_plans.upgrade_modal.subheading", {
        feature_name: t("settings.community_ai.ai_agents_feature_name"),
      }),
      successButtonLabel: t(
        "pricing_plans.upgrade_modal.actions.go_to_community_ai",
      ),
      showPlanDetails: true,
    });
  };

  const handleToggle = () => {
    if (!currentCommunity?.ai_agents_feature_flag_enabled) {
      handleUpgradePlan().catch(error => {
        console.error("Failed to handle upgrade plan:", error);
      });
    } else if (row.original.enabled) {
      void disableModal.show();
    } else {
      void enableModal.show();
    }
  };

  return (
    <div id={`toggle-wrapper-agent-${row.original.id}`}>
      <ToggleWithLoader
        isChecked={row.original?.enabled}
        checkedLabel={t("settings.community_bot.agents.on_value")}
        uncheckedLabel={t("settings.community_bot.agents.off_value")}
        tooltipCheckedLabel=""
        tooltipUncheckedLabel=""
        tooltipPlacement="bottom"
        isLoading={false}
        isDisabled={false}
        onChange={handleToggle}
        containerClassName=""
        className=""
      />
    </div>
  );
};
