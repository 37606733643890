import type { WorkflowType } from "@circle-react/components/SettingsApp/Workflows/constants";
import { Icon } from "@circle-react-shared/Icon";
import type { TriggerTypes } from "@circle-react-shared/WorkflowsV2/TriggerNode/constants";
import type { Audience } from "../../../generated/WorkflowsApi.types";
import { Action } from "../Action";
import { useEntryRule } from "./useEntryRule";

export interface EntryRuleProps {
  trigger: {
    trigger_type: TriggerTypes;
  };
  audience: Audience;
  workflowType: WorkflowType;
}

export const EntryRule = ({
  trigger,
  audience,
  workflowType,
}: EntryRuleProps) => {
  const entryRule = useEntryRule({
    trigger,
    audience,
    workflowType,
  });

  if (!entryRule) {
    return null;
  }

  const { title, iconType } = entryRule;

  return (
    <Action tooltipContent={title}>
      <Icon
        type={iconType}
        size={24}
        useWithFillCurrentColor
        className="!h-5 !w-5"
      />
    </Action>
  );
};
