import { useState } from "react";

const VALID_PAYMENT_METHODS = [
  "card",
  // "google_pay", This payment is enabled by "card"
  // "apple_pay", This payment is enabled by "card"
  // "pix", Our integration does not use PE to pay through PIX
  "link",
  "klarna",
  "afterpay_clearpay",
  "affirm",
];

export const useFilterPaymentMethodsSupported = (
  currentPaymentMethodsSupported: string[],
): [string[], React.Dispatch<React.SetStateAction<string[]>>] => {
  const [filteredPaymentMethodsSupported, setPaymentMethodsSupported] =
    useState<string[]>(
      currentPaymentMethodsSupported.filter((paymentMethod: string) =>
        VALID_PAYMENT_METHODS.includes(paymentMethod),
      ),
    );

  return [filteredPaymentMethodsSupported, setPaymentMethodsSupported];
};
