import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import avatars from "@circle-assets/images/inbox-avatars.png";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";
import { useIndexAgents } from "../CommunityBot/agentsQueries";

export const EmptyState = () => {
  const history = useHistory();
  const { data: agentsQuery, isLoading } = useIndexAgents({ currentPage: 1 });
  const shouldDisplaySetupAgentCTA = !isLoading && !agentsQuery?.count;

  const onSetupAgentClick = () => {
    history.push(settingsNavPathV3.community_bot.agents);
  };

  return (
    <div className="bg-secondary flex h-full w-full items-center justify-center text-center">
      <div className="flex w-[31rem] flex-col items-center gap-4">
        <img
          alt=""
          loading="lazy"
          src={avatars}
          className="mb-2 object-cover"
        />
        <div className="flex flex-col items-center gap-2">
          <Typography.LabelLg weight="semibold">
            {t("community_inbox.title")}
          </Typography.LabelLg>
          <Typography.BodyMd color="text-default">
            {t("community_inbox.description")}
          </Typography.BodyMd>
        </div>
        {shouldDisplaySetupAgentCTA && (
          <Button variant="primary" onClick={onSetupAgentClick} large>
            {t("community_inbox.setup_agent_cta")}
          </Button>
        )}
      </div>
    </div>
  );
};
