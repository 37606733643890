import { t } from "@/i18n-js/instance";
import { usePauseAiAction } from "@/react/components/SettingsApp/CommunityInbox/hooks/usePauseAiAction";
import { Icon } from "@circle-react-shared/Icon";
import { useToast } from "@circle-react-uikit/ToastV2";
import { Typography } from "@circle-react-uikit/Typography";

export const PauseAIButton = ({ chat }: { chat: any }) => {
  const toastr = useToast();
  const { pauseAi, isPauseAiLoading, resumeAi, isResumeAiLoading } =
    usePauseAiAction();

  const handleResumeAi = async () => {
    try {
      await resumeAi(chat.id);
    } catch (error) {
      toastr.error(t("messaging.actions.pause_ai_error"));
    }
  };

  const handlePauseAi = async () => {
    try {
      await pauseAi(chat.id);
    } catch (error) {
      toastr.error(t("messaging.actions.pause_ai_error"));
    }
  };

  if (chat.ai_snoozed) {
    return (
      <button
        type="button"
        onClick={handleResumeAi}
        className="h-[34px] rounded-full border bg-[#15803D] px-4 transition-all duration-300 hover:bg-[#15803D]/90"
      >
        <div className="flex items-center space-x-1">
          <Icon
            type={isResumeAiLoading ? "loader" : "16-ai-agent"}
            size={16}
            className="text-white"
          />
          <Typography.LabelSm weight="bold" color="text-white">
            {t(
              isResumeAiLoading
                ? "community_bot.ai_resuming"
                : "community_bot.resume_ai",
            )}
          </Typography.LabelSm>
        </div>
      </button>
    );
  }
  return (
    <button
      type="button"
      onClick={handlePauseAi}
      className="hover:bg-secondary h-[34px] rounded-full border px-4 transition-all duration-300"
    >
      <div className="flex items-center space-x-1">
        <Icon
          type={isPauseAiLoading ? "loader" : "16-ai-snooze"}
          size={16}
          className="text-dark font-medium"
        />
        <Typography.LabelSm weight="medium">
          {t(
            isPauseAiLoading
              ? "community_bot.ai_pausing"
              : "community_bot.pause_ai.title",
          )}
        </Typography.LabelSm>
      </div>
    </button>
  );
};
