import { t } from "@/i18n-js/instance";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

export const NoSpaces = () => (
  <div>
    <div className="homepage__nav">
      <h2 className="homepage__title" data-testid="space-title-name">
        {t("home_page.title")}
      </h2>
    </div>
    <div
      className={classNames("space__gated locked-pane !rounded-2xl")}
      data-testid="post_section"
    >
      <h3>{t("landing_page.settings.empty_suggested_spaces_text")}</h3>
    </div>
  </div>
);
