import type { ChatType } from "@/react/types/CommunityBot/ChatSort";
import type { IconType } from "@circle-react-shared/Icon";

export const CHAT_SORT_VALUES = {
  LATEST: "latest",
  OLDEST: "oldest",
} as const;

export const CHAT_TYPES = {
  ALL: "all",
  UNREAD: "admin_unread",
  AI_PAUSED: "ai_paused",
} as const;

export const CHAT_TYPES_DROPDOWN: Record<ChatType, ChatTypeDropdownItem> = {
  [CHAT_TYPES.ALL]: { value: CHAT_TYPES.ALL, icon: "20-inbox" },
  [CHAT_TYPES.UNREAD]: { value: CHAT_TYPES.UNREAD, icon: "20-inbox-unread" },
  [CHAT_TYPES.AI_PAUSED]: {
    value: CHAT_TYPES.AI_PAUSED,
    icon: "20-inbox-snooze",
  },
};

export interface ChatTypeDropdownItem {
  value: ChatType;
  icon: IconType;
}

export const CHAT_TYPES_DROPDOWN_ITEMS: ChatTypeDropdownItem[] =
  Object.values(CHAT_TYPES_DROPDOWN);
