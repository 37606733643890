import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { CommunityInfo } from "./CommunityInfo";
import { PaywallPrice } from "./PaywallPrice";

interface CommunityAndPaywallPriceInfoProp {
  className?: string;
}

export const CommunityAndPaywallPriceInfo = ({
  className,
}: CommunityAndPaywallPriceInfoProp) => (
  <div
    className={classNames(
      "flex flex-col p-6 md:p-20 md:px-14 lg:p-20 lg:px-36",
      className,
    )}
    data-testid="paywall-info"
  >
    <div className="w-full">
      <CommunityInfo />
      <PaywallPrice />
    </div>
  </div>
);
