import { Video } from "@circle-react-shared/Video";

export interface DisplayMediaProps {
  isVideo?: boolean;
  mediaUrl: string;
}

export const DisplayMedia = ({
  isVideo = false,
  mediaUrl,
}: DisplayMediaProps) => {
  if (isVideo) {
    return (
      <div className="welcome-banner__video">
        <div className="video-content">
          <Video url={mediaUrl} shouldDisableDownload />
        </div>
      </div>
    );
  }

  return (
    <div className="welcome-banner__image">
      <div className="image-content">
        <img loading="lazy" alt="" src={mediaUrl} />
      </div>
    </div>
  );
};
