import type {
  ChatSortType,
  ChatType,
} from "@circle-react/types/CommunityBot/ChatSort";
import { ChatTypesDropdown } from "./ChatTypesDropdown";
import { DropdownMenu } from "./DropdownMenu";
import { CHAT_SORT_VALUES } from "./constants";

interface HeaderProps {
  activeSort: ChatSortType;
  setActiveSort: (value: ChatSortType) => void;
  activeChatType: ChatType;
  setActiveChatType: (value: ChatType) => void;
}
export const Header = ({
  activeSort,
  setActiveSort,
  activeChatType,
  setActiveChatType,
}: HeaderProps) => (
  <div className="bg-primary sticky top-0 z-10 my-1 flex h-10 items-center justify-between gap-2 px-4">
    <ChatTypesDropdown
      className="min-w-fit"
      activeItem={activeChatType}
      localeScope="community_inbox.filters"
      onItemClick={value => setActiveChatType(value)}
    />
    <DropdownMenu
      className="w-24"
      items={Object.values(CHAT_SORT_VALUES)}
      activeItem={activeSort}
      localeScope="community_inbox.filters"
      onItemClick={value => setActiveSort(value)}
    />
  </div>
);
